import * as Sentry from "@sentry/browser";

import.meta.glob([
    '../images/**',
]);

if (import.meta.env.VITE_SENTRY_JS_DSN) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_JS_DSN,
        integrations: [
            new Sentry.BrowserTracing(),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
        // Session Replay
        replaysSessionSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
        replaysOnErrorSampleRate: 1.0,
    });
}

// Track all form submissions as a Fathom event
document.querySelectorAll('form').forEach(f => {
    f.addEventListener('submit', e => {
        let url = new URL(e.target.action);
        let segments = url.pathname.split('/');
        let trackSubmitEventName = segments.pop() || segments.pop();  // handle potential trailing slash

        window.fathom && window.fathom.trackEvent(trackSubmitEventName);
    });
});
